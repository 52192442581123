<template>
  <section>
    <div class="content-header">
      <h2>Review your syllabus and course content with an EDI lens </h2>
    </div>
    <div class="content-wrapper">
      <p>Building curriculum that promotes inclusivity, belonging and resilience among students requires representing diverse voices and identities within our course content. Review your syllabus, and consider which voices or identities are represented, and more importantly, which ones are not?</p>
      <ul>
        <li class="pb-2">Which topics are covered in my course, and which ones are not?</li>
        <li class="pb-2">Have I utilized universal design principles to create an inclusive environment for students with disabilities?</li>
        <li class="pb-2">Is my course reinforcing dominant, homogeneous worldviews in the content, or ideas presented? </li>
        <li class="pb-2">Do readings I include come from authors with diverse identities and worldviews?</li>
        <li class="pb-2">If I include cases or stories about people, are a diversity of identities authentically included?</li>
      </ul>
      <p>Consider utilizing the following resources if you want to learn more about building inclusive classroom environments:</p>
      <ul>
        <li><a href="https://inclusiveteaching.ctlt.ubc.ca/" target="_blank">Inclusive Teaching at University of British Columbia</a></li>
        <li>Ivey EDI case review rubric (used with permission from Nadine de Gannes)</li>
        <li><a :href="`${publicPath}docs/personal/_BP - Feedback to encourage resilience.pdf`" target="_blank">Western Student Experience Anti-Oppression Rubric for Program / Service design and delivery</a> (used with permission from Rick Ezekiel)</li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
